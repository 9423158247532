/*AppleSDGothicNeo*/
/*font-family: 'AppleSDGothicNeo' !important;*/

@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 700;
  font-style: normal;
  src: local("AppleSDGothicNeo Bold"), local("AppleSDGothicNeoB"),
    url("../assets/AppleSDGothicNeo/AppleSDGothicNeoB.ttf?#iefix")
      format("truetype");
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 400;
  font-style: normal;
  src: local("AppleSDGothicNeo Regular"), local("AppleSDGothicNeoR"),
    url("../assets/AppleSDGothicNeo/AppleSDGothicNeoR.ttf?#iefix")
      format("truetype");
}
